import { LOADER_ON, LOADER_OFF } from '../actions/types';

const INTIAL_STATE = {};

export default function (state = INTIAL_STATE, action) {
	switch (action.type) {
		case LOADER_ON:
			return {
				...action.payload,
			};
		case LOADER_OFF:
			return { ...action.payload };
		default:
			if (state && action.type === '@@INIT') {
				let persist_token = localStorage.getItem('token')
					? localStorage.getItem('token')
					: localStorage.getItem('admin_token');

				return persist_token ? { data: persist_token } : state;
			} else {
				return state;
			}
	}
}
