import { UPLOAD_FILE, REMOVE_FILE } from '../actions/types';

const INTIAL_STATE = {};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INTIAL_STATE, action) {
  switch (action.type) {
    case UPLOAD_FILE:
      return {
        ...action.payload,
      };
    case REMOVE_FILE:
      return {};
    default:
      // persist token only if page reload
      if (state && action.type === '@@INIT') {
        let persist_token = localStorage.getItem('token')
          ? localStorage.getItem('token')
          : localStorage.getItem('admin_token');

        return persist_token ? { data: persist_token } : state;
      } else {
        return state;
      }
  }
}
