import { createStore } from 'redux';
import { persistStore } from 'redux-persist'; // persist the current state
//import { AppCombineReducers } from "./combineReducers"; //if you don't want to use redux-persist
import { persistedReducer } from './combineReducers';
import { Enhancers } from './enhancer'; // to apply middleware and use REDUX_DEVTOOLS_EXTENSION

// create store with enhancers
let store = createStore(persistedReducer, Enhancers);

// persist store
let persistor = persistStore(store);

// export both
export { store, persistor };

//if you don't want to use redux-persist
//export const createAppStore = createStore(AppCombineReducers, Enhancers);
