import { INVOICE_SET_FILTER } from '../actions/types';

const initialState = {
	filters: {
		start_date: null,
		end_date: null,
		invoice_no: '',
		distributor_id: '',
		distributor_name: '',
		page_no: 1,
	},
};

const filtersReducers = (state = initialState, action) => {
	switch (action.type) {
		case INVOICE_SET_FILTER:
			const updatedFilters = {
				...state.filters,
				...action.filter,
			};
			if (updatedFilters.distributor_id === '') {
				updatedFilters.distributor_name = '';
			}
			const newState = {
				...state,
				filters: updatedFilters,
			};

			return newState;

		default:
			return state;
	}
};

export default filtersReducers;
