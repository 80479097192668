

const INTIAL_STATE = { doctor: '', distributor: '', cfa_code: '', division: '' };

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INTIAL_STATE, action) {
  switch (action.type) {
    case 'ADD_DATA':

      return {
        doctor: action.payload?.data?.doctor,
        distributor: action.payload?.data?.distributor,
        cfa_code: action.payload?.data?.cfa_code,
        division: action.payload?.data?.division

      };
    case 'DIS_DATA':
      return {
        ...state,
        distributor: action.payload
      };

    case 'DOC_DATA':
      return {
        ...state,
        doctor: action.payload,

      };
    default:
      // persist token only if page reload
      if (state && action.type === '@@INIT') {
        let persist_token = localStorage.getItem('token')
          ? localStorage.getItem('token')
          : localStorage.getItem('admin_token');

        return persist_token ? { data: persist_token } : state;
      } else {
        return state;
      }
  }
}
