import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist'; // persist the current state
import storage from 'redux-persist/lib/storage'; // to store state data into Local storage
//import storageSession from 'redux-persist/lib/storage/session' // for session storage

// Import reducer files
import auth from './reducers/auth';

import uploadOrder from './reducers/uploadOrder';
import loader from './reducers/forLoader';
import cart from './reducers/cart_reducers';
import fileUpload from './reducers/fileUpload';
import cart_back_reducer from './reducers/cart_back_reducer';
import invoice from './reducers/invoiceReducer';
// assign the root and storage in persist configuration
const persistConfig = {
	key: 'root',
	storage,
};
// use whitelist:['auth'] optional key if you want to persist only auth reducer

export const AppCombineReducers = combineReducers({
	auth: auth,
	cart: cart,
	uploadOrder: uploadOrder,
	loader: loader,
	fileUpload: fileUpload,
	cart_back_reducer: cart_back_reducer,
	//adminLogin: adminLogin,
	invoice: invoice,
});
// overwrite combine reducer functions
export const persistedReducer = persistReducer(persistConfig, AppCombineReducers);
