import React from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './store/createStore';
import { PersistGate } from 'redux-persist/integration/react';
// import { StyledEngineProvider } from "@mui/material/styles";
// import ReactDOM from "react-dom/client";
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { BrowserRouter } from "react-router-dom";
// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//     <App />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { BrowserRouter } from 'react-router-dom';
import ReactDOMClient from 'react-dom/client';
import App from './App';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			{/* <React.StrictMode> */}
			{/* <StyledEngineProvider injectFirst> */}
			<App />
			{/* </React.StrictMode> */}
			{/* </StyledEngineProvider> */}
		</PersistGate>
	</Provider>
);
