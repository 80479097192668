import 'bootstrap/dist/css/bootstrap.min.css';
import React, { CSSProperties, lazy, Suspense } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { shallowEqual, useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
// import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import './assets/css/style.css';
import './assets/css/media.css';
const InvoiceDetailsPage = lazy(() => import('./components/pages/my-invoices/InvoiceDetailsPage'));
const DragAndDrop = lazy(() => import('./components/pages/my-invoices/DragAndDrop'));
// import Layout from "./components/layout/Layout";
const Layout = lazy(() => import('./components/layout/Layout'));
const Notification = lazy(() => import('./components/pages/notification/Notification'));

const Home = lazy(() => import('./components/pages/home/Home'));
const MIS = lazy(() => import('./components/pages/mis/MIS'));
const MyInvoices = lazy(() => import('./components/pages/my-invoices/MyInvoices'));
const ShadowPage = lazy(() => import('./components/pages/login/ShadowPage'));
const Dashboard = lazy(() => import('./components/pages/dashboard/Dashboard'));
const MyOrders = lazy(() => import('./components/pages/orders/MyOrders'));
const DepotMyOrders = lazy(() => import('./components/pages/orders/DepotOrders'));

const MyProfile = lazy(() => import('./components/pages/profile/Profile'));

const MyOrdersDetails = lazy(() => import('./components/pages/orders/MyOrdersDetails'));
const DepotMyOrderDetails = lazy(() => import('./components/pages/orders/DepotMyOrdersDetails'));

const OrderConformationDetails = lazy(() => import('./components/pages/orders/OrderConformationDetails'));
const OrderReviewDetails = lazy(() => import('./components/pages/orders/OrderReviewDetails'));
const OrderConfirmationDiscountDetails = lazy(() =>
	import('./components/pages/orders/OrderConformationDiscountDetails')
);
const DepoFileViewer = lazy(() => import('./components/pages/orders/DepoFileViewer'));
const MyOrderDiscount = lazy(() => import('./components/pages/orders/MyOrderDiscount'));
const DepotMyOrderDiscount = lazy(() => import('./components/pages/orders/DepotMyOrderDiscount'));

const UfOrders = lazy(() => import('./components/pages/orders/UnfulfilledOrders'));

const UfOrderDetails = lazy(() => import('./components/pages/orders/UnfulfilledOrdersDetails'));

const Notes = lazy(() => import('./components/pages/notes/Notes'));

const Products = lazy(() => import('./components/pages/products/Products'));

const ProductDiscountBusiness = lazy(() => import('./components/pages/products/ProductDiscountBusiness'));
const OrderConformations = lazy(() => import('./components/pages/orders/OrderConformation'));
const GenericOrderConformations = lazy(() => import('./components/pages/orders/GenericOrderConformation'));
const OrderConformationsDiscount = lazy(() => import('./components/pages/orders/OrderConformationDiscount'));
const UploadOrder = lazy(() => import('./components/pages/upload-order/UploadOrder'));
const UploadOrderDetaile = lazy(() => import('./components/pages/upload-order/Upload'));

const UploadOrderDetails = lazy(() => import('./components/pages/upload-order/UploadOrderDetails'));

//po-upload

const UploadPO = lazy(() => import('./components/pages/upload-po/UploadPO'));
const UploadedPODetails = lazy(() => import('./components/pages/upload-po/UploadedPODetails'));

const Cart = lazy(() => import('./components/pages/cart/Cart'));
const UpdateCart = lazy(() => import('./components/pages/cart/Update-Cart'));
const ContactUs = lazy(() => import('./components/pages/contact-us/ContactUs'));
const FAQ = lazy(() => import('./components/pages/faq/Faq'));
const AdminFaq = lazy(() => import('./components/pages/admin/faq/Faq'));
const Login = lazy(() => import('./components/pages/login/Login'));
const Deshboard = lazy(() => import('./components/pages/admin/dashboard/Dashboard'));
const AlkemLitesLogin = lazy(() => import('./components/pages/login/AlkemitesLogin'));
const MarketPlaceLogin = lazy(() => import('./components/pages/login/MarketPlaceLogin'));

const ForgotPassword = lazy(() => import('./components/pages/login/ForgotPassword'));

const ResetPassword = lazy(() => import('./components/pages/login/ResetPassword'));

// const Banner = lazy(() => import("./components/pages/banner/Banner"));
const CustomerProfile = lazy(() => import('./components/pages/customer-profile/CustomerProfile'));
const Outstanding = lazy(() => import('./components/pages/outstanding/Outstanding'));
const DistributorOutstanding = lazy(() => import('./components/pages/distributorOutstanding/Outstanding'));
const ProductsList = lazy(() => import('./components/pages/products/ProductsList'));
const PageNotFound = lazy(() => import('./components/pages/page-not-found/PageNotFound'));

const AdminLayout = lazy(() => import('./components/pages/admin/layout/Layout'));
const AdminDashboard = lazy(() => import('./components/pages/admin/dashboard/Dashboard'));
const AdminOrders = lazy(() => import('./components/pages/admin/orders/AdminOrders'));
const AdminOutstanding = lazy(() => import('./components/pages/admin/outstanding/AdminOutstanding'));
const AdminOrderDetails = lazy(() => import('./components/pages/admin/orders/AdminMyOrdersDetails'));
const AdminOrderSapDetails = lazy(() => import('./components/pages/admin/orders/AdminMyOrderSapDetails'));

const AdminOrdersDiscounted = lazy(() => import('./components/pages/admin/orders/AdminOrdersDiscounted'));
const AdminOrdersDiscountDetails = lazy(() => import('./components/pages/admin/orders/AdminMyOrderDiscountDetails'));

const AdminUnfulfilledOrders = lazy(() => import('./components/pages/admin/orders/AdminUnfulfilledOrders'));
const AdminUnfulfilledOrdersDetails = lazy(() =>
	import('./components/pages/admin/orders/AdminUnfulfilledOrdersDetails')
);
const AdminProducts = lazy(() => import('./components/pages/admin/products/AdminProducts'));
const AdminProductsDiscounted = lazy(() => import('./components/pages/admin/products/AdminProductsDiscounted'));
const AdminLogin = lazy(() => import('./components/pages/admin/login/AdminLogin'));
const AdminContactUs = lazy(() => import('./components/pages/admin/contactus/AdminContactUs'));

const AdminNotes = lazy(() => import('./components/pages/admin/notes/AdminNotes'));

const AdminBanner = lazy(() => import('./components/pages/admin/banner/AdminBanner'));
const AdminCustomerProfile = lazy(() => import('./components/pages/admin/customer-profile/AdminCustomerProfile'));
const AdminManageHospital = lazy(() => import('./components/pages/admin/Manage-hospital/AdminManageHospital'));
const AdminManageProductImage = lazy(() => import('./components/pages/admin/manage-product-image/ManageProductImage'));
const AdminManageDoctor = lazy(() => import('./components/pages/admin/Manage-doctor/AdminManageDoctor'));
const AdminManageEmployee = lazy(() => import('./components/pages/admin/employee/AdminManageEmployee'));
const AdminCreateEmployee = lazy(() => import('./components/pages/admin/employee/CreateEmployee'));
const AdminUpdateEmployee = lazy(() => import('./components/pages/admin/employee/UpdateEmployee'));
const AdminManageOrder = lazy(() => import('./components/pages/admin/Manage-order/AdminManageOrder'));
const AdminManageGenericOrder = lazy(() => import('./components/pages/admin/Manage-order/AdminManageGenericOrder'));
const AdminMyInvoices = lazy(() => import('./components/pages/admin/my-invoices/AdminMyInvoice'));
const AdminInvoiceDetailsPage = lazy(() => import('./components/pages/admin/my-invoices/AdminInvoiceDetailsPage'));
const AdminManageCron = lazy(() => import('./components/pages/admin/Manage-Cron/AdminManageCron'));
const ManageOrderLock = lazy(() => import('./components/pages/admin/manage-order-lock/ManageOrderLock'));
const MangeBonusOffer = lazy(() => import('./components/pages/admin/Mange-bonus-offer/MangeBonusOffer'));
const ManagePlant = lazy(() => import('./components/pages/admin/manage-plant/ManagePlant'));

// Private Route for inner component
// const override: CSSProperties = {
//   display: "block",
//   margin: "0 auto",
//   borderColor: "red",
// };

// This is for when you want to override  console function. If want to  show console then  remove this  line.

// if (process.env.NODE_ENV === "qa") {
//console.log = function () {};
// }

const ProductTypePrivateRoute = () => {
	const productType = useSelector((s) => s.auth?.tokenDecode?.productType ?? 'E', shallowEqual);
	return <>{productType === 'E' ? <Outlet /> : <Navigate to="/home" />}</>;
};

const ProductTypePrivateRouteGeneric = () => {
	const productType = useSelector((s) => s.auth?.tokenDecode?.productType ?? 'G', shallowEqual);
	return <>{productType === 'G' ? <Outlet /> : <Navigate to="/home" />}</>;
};

const PrivateRoute1 = () => {
	let userid = localStorage.getItem('alkem_token');

	if (userid)
		return (
			<Layout>
				<Outlet />
			</Layout>
		);
	return <Navigate to="/" />;
};

const PrivateRoute2 = () => {
	let userid = localStorage.getItem('alkem_admin_token');

	if (userid)
		return (
			<AdminLayout>
				<Outlet />
			</AdminLayout>
		);
	return <Navigate to="/admin/login" />;
};

const PrivateRoute3 = () => {
	let userid = localStorage.getItem('alkem_token');
	let useridAdm = localStorage.getItem('alkem_admin_token');

	if (userid || useridAdm) return <Outlet />;
	return <Navigate to="/" />;
};
const ForEmployeeOnly = () => {
	const userType = useSelector((s) => s.auth?.tokenDecode?.userType ?? '', shallowEqual);
	if (userType === 'Employee') return <Outlet />;
	else return <Navigate to="/home" />;
};
const ForAdminBrandedGenricOnly = () => {
	const mapStateToProps = (state) => ({
		adminProductType: state.auth?.adminProductType,
	});
	const { adminProductType } = useSelector(mapStateToProps, shallowEqual);

	if (adminProductType === 'E') return <Outlet />;
	else return <Navigate to="/admin" />;
};

const IfNotLogin = () => {
	const userType = useSelector((s) => s.auth?.tokenDecode?.userType ?? '', shallowEqual);
	console.log(userType);
	if (userType) return <Navigate to="/home" />;
	else return <Outlet />;
};

const ForDistributorOnly = () => {
	const userType = useSelector((s) => s.auth?.tokenDecode?.userType ?? '', shallowEqual);
	if (userType === 'Distributor') return <Outlet />;
	else return <Navigate to="/home" />;
};

export default function App() {
	return (
		<Router basename={process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : ''}>
			<Suspense
				fallback={
					<center className="mt-5">
						<PulseLoader
							color={'#1E4696'}
							css={{
								display: 'block',
								margin: '0 auto',
								borderColor: 'red',
							}}
							verticalAlign="middle"
							margin={14}
							size={20}
						/>
					</center>
				}
			>
				<Routes>
					<Route path="/" element={<Login />} />
					<Route exact path="/admin/login" element={<AdminLogin />} />
					{/*    <Route path="/employee-login" element={<AlkemLitesLogin />} />
          <Route path="/customer-login" element={<MarketPlaceLogin />} /> */}

					<Route path="/alkemites-login" element={<AlkemLitesLogin />} />
					<Route path="/marketplace-login" element={<MarketPlaceLogin />} />

					<Route path="/auth/" element={<ShadowPage />} />

					<Route exact element={<IfNotLogin />}>
						<Route path="/forgot-password" element={<ForgotPassword />} />
						<Route path="/reset_password/:token" element={<ResetPassword />} />
					</Route>

					<Route exact element={<PrivateRoute2 />}>
						<Route exact path="/admin" element={<AdminDashboard />} />
						<Route exact path="/admin/orders" element={<AdminOrders />} />
						<Route exact path="/admin/my_order_details/:order_id" element={<AdminOrderDetails />} />
						<Route exact path="/admin/order/my_sap_order_details/:order_ref" element={<AdminOrderSapDetails />} />
						<Route exact element={<ForAdminBrandedGenricOnly />}>
							<Route exact path="/admin/orders-discounted" element={<AdminOrdersDiscounted />} />
							<Route exact path="/admin/product-discount-buisness" element={<AdminProductsDiscounted />} />
							<Route exact path="/admin/discounted_order_details/:order_id" element={<AdminOrdersDiscountDetails />} />
						</Route>
						<Route exact path="/admin/unfulfilled-orders" element={<AdminUnfulfilledOrders />} />
						<Route
							exact
							path="/admin/unfulfilled-orders-details/:order_id"
							element={<AdminUnfulfilledOrdersDetails />}
						/>
						<Route exact path="/admin/notes" element={<AdminNotes />} />
						<Route exact path="/admin/faq" element={<AdminFaq />} />
						<Route exact path="/admin/dashboard" element={<AdminDashboard />} />
						<Route exact path="/admin/products" element={<AdminProducts />} />
						<Route exact path="/admin/banner" element={<AdminBanner />} />
						<Route exact path="/admin/customer-profile" element={<AdminCustomerProfile />} />
						<Route exact element={<ForAdminBrandedGenricOnly />}>
							<Route exact path="/admin/manage-hospital" element={<AdminManageHospital />} />
							<Route exact path="/admin/manage-doctor" element={<AdminManageDoctor />} />
						</Route>
						<Route exact path="/admin/employee" element={<AdminManageEmployee />} />
						<Route exact path="/admin/employee/create_employee" element={<AdminCreateEmployee />} />
						<Route exact path="/admin/employee/update_employee/:id" element={<AdminUpdateEmployee />} />
						<Route exact path="/admin/manage-order" element={<AdminManageOrder />} />
						<Route exact path="/admin/manage-generic-order" element={<AdminManageGenericOrder />} />
						<Route exact path="/admin/my-invoice" element={<AdminMyInvoices />} />
						<Route exact path="/admin/manage-cron" element={<AdminManageCron />} />
						<Route exact path="/admin/my-invoice-details/:invoiceId" element={<AdminInvoiceDetailsPage />} />
						<Route exact path="/admin/contact-us" element={<AdminContactUs />} />
						<Route exact path="/admin/current-outstanding" element={<AdminOutstanding />} />
						<Route exact path="/admin/manage-order-lock" element={<ManageOrderLock />} />{' '}
						<Route exact path="/admin/manage-product-image" element={<AdminManageProductImage />} />
						<Route exact path="/admin/mange-bonus-offer" element={<MangeBonusOffer />} />
						<Route exact path="/admin/mange-plant" element={<ManagePlant />} />
						<Route exact path="admin/dashboard" element={<Deshboard />} />
					</Route>
					<Route exact element={<PrivateRoute3 />}>
						<Route exact path="/view-po/:id" element={<DepoFileViewer />} />

						{/* <Route exact path="/dashboard" element={<Dashboard />} /> */}
					</Route>
					<Route exact element={<PrivateRoute1 />}>
						<Route exact path="/faq" element={<FAQ />} />
						<Route exact path="/home" element={<Home />} />
						<Route exact path="/notification" element={<Notification />} />
						<Route exact path="/mis" element={<MIS />} />
						{/*   <Route exact path="/dashboard" element={<Dashboard />} /> */}
						<Route exact path="/my-invoice" element={<MyInvoices />} />

						<Route exact path="/my-invoice-details/:invoiceId" element={<InvoiceDetailsPage />} />
						<Route exact path="/my-invoice-details/drag-and-drop" element={<DragAndDrop />} />
						<Route exact path="/my-orders" element={<MyOrders />} />
						<Route exact path="/depot-my-orders" element={<DepotMyOrders />} />
						<Route exact path="/my-order-details/:order_id" element={<MyOrdersDetails orderCategory="nd" />} />
						<Route
							exact
							path="/depot-my-order-details/:order_id"
							element={<DepotMyOrderDetails orderCategory="nd" />}
						/>

						<Route exact path="/notes" element={<Notes />} />

						<Route exact path="/products" element={<Products />} />
						<Route exact path="/products-list" element={<ProductsList />} />
						<Route exact path="/upload-order" element={<UploadOrder />} />
						<Route exact path="/upload-order-demo" element={<UploadOrderDetaile />} />

						<Route exact path="/upload-order-details" element={<UploadOrderDetails />} />

						<Route exact path="/upload-po" element={<UploadPO />} />
						<Route exact path="/po-details" element={<UploadedPODetails />} />

						<Route exact path="/contact-us" element={<ContactUs />} />
						<Route exact path="/cart" element={<Cart />} />

						<Route exact path="/my-profile" element={<MyProfile />} />

						<Route exact element={<ForDistributorOnly />}>
							<Route exact element={<ProductTypePrivateRoute />}>
								<Route exact path="/order-confirmation" element={<OrderConformations />} />

								<Route exact path="/order-confirmation-discount" element={<OrderConformationsDiscount />} />
								<Route
									exact
									path="/order-confirmation-details/:order_id"
									element={<OrderConformationDetails orderCategory="nd" />}
								/>
								<Route
									exact
									path="/order-review-details/:order_id"
									element={<OrderReviewDetails orderCategory="nd" />}
								/>

								<Route
									exact
									path="/order-confirmation-discount-details/:order_id"
									element={<OrderConfirmationDiscountDetails orderCategory="d" />}
								/>
							</Route>
							<Route exact element={<ProductTypePrivateRouteGeneric />}>
								<Route exact path="/generic-order-confirmation" element={<GenericOrderConformations />} />
							</Route>
							<Route exact path="/my-outstanding" element={<DistributorOutstanding />} />
							<Route exact path="/update-cart" element={<UpdateCart />} />
						</Route>

						<Route exact element={<ForEmployeeOnly />}>
							<Route exact path="/unfulfilled-orders" element={<UfOrders />} />
							<Route exact path="/unfulfilled-order-details/:order_id" element={<UfOrderDetails />} />
							{/* <Route exact path="/banners-list" element={<Banner />} /> */}
							<Route exact path="/customer-profile" element={<CustomerProfile />} />

							<Route exact path="/outstanding" element={<Outstanding />} />
						</Route>

						<Route exact element={<ProductTypePrivateRoute />}>
							<Route exact path="/my-orders-discount" element={<MyOrderDiscount />} />
							<Route exact path="/my-orders-discount-depot" DepotMyOrderDetails element={<DepotMyOrderDiscount />} />
							{/* <Route exact path="/view-po/:id" element={<DepoFileViewer />} /> */}
							<Route exact path="/product-discount-buisness" element={<ProductDiscountBusiness />} />
							<Route
								exact
								path="/my-order-discount-details/:order_id"
								element={<MyOrdersDetails orderCategory="d" />}
							/>
							<Route
								exact
								path="/depot-my-order-discount-details/:order_id"
								element={<DepotMyOrderDetails orderCategory="d" />}
							/>

							<Route
								exact
								path="/api/order/my_sap_order_details/:order_ref"
								element={<MyOrdersDetails orderOrigin="SAP" />}
							/>
							<Route
								exact
								path="/api/order/depot_my_sap_order_details/:order_ref"
								element={<DepotMyOrderDetails orderOrigin="SAP" />}
							/>
						</Route>
					</Route>
					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</Suspense>
		</Router>
	);
}
