import {
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT_SUCCESS,
	PRODUCT_TYPE,
	ADMIN_LOGIN_SUCCESS,
	ADMIN_LOGIN_FAIL,
	ADMIN_LOGOUT_SUCCESS,
	ADMIN_PRODUCT_TYPE,
} from '../actions/types';
import jwt_decode from 'jwt-decode';
const INTIAL_STATE = { data: '', errors: {} };
export function getDivType(token) {
	try {
		switch (token.div_type) {
			case 'B':
				return 'Both';
			default:
				return token.div_type;
		}
	} catch (error) {
		// localStorage.clear();
		console.log(error);
		window.location.href = '';
	}
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INTIAL_STATE, action, adminProductType = 'E') {
	switch (action.type) {
		case ADMIN_LOGIN_SUCCESS:
			let tokenDecodeAdmin = jwt_decode(action.payload.token);
			console.log(action.payload.token, 'action');
			return {
				...state,
				isAuthenticatedAdmin: true,
				tokenAdmin: action.payload.token,
				tokenDecodeAdmin: tokenDecodeAdmin,
			};
		case LOGIN_SUCCESS:
			let tokenDecode = jwt_decode(action.payload.token);
			tokenDecode.userType =
				tokenDecode.type === 'E' ? 'Employee' : tokenDecode.type === 'D' ? 'Distributor' : tokenDecode.type;
			tokenDecode.DepotLogin = tokenDecode.depot_login;
			tokenDecode.DivType = getDivType(tokenDecode);

			return {
				...state,
				isAuthenticated: true,
				token: action.payload.token,
				tokenDecode: tokenDecode,
			};

		case LOGIN_FAIL:
			return {
				adminProductType: state?.adminProductType ?? '',
				adminProductType: state?.adminProductType ?? '',
				isAuthenticatedAdmin: state?.isAuthenticatedAdmin ?? '',
			};
		case ADMIN_LOGIN_FAIL:
			return {
				...state,
				errors: action.payload,
			};
		case LOGOUT_SUCCESS:
			return {
				adminProductType: state?.adminProductType ?? '',
				isAuthenticatedAdmin: state?.isAuthenticatedAdmin ?? '',
				tokenDecodeAdmin: state?.tokenDecodeAdmin ?? '',
			};

		case ADMIN_LOGOUT_SUCCESS:
			return { ...state };
		case PRODUCT_TYPE:
			state.tokenDecode.productType = action.payload.productType;

			return {
				...state,
			};
		case ADMIN_PRODUCT_TYPE:
			state['adminProductType'] = action.payload.productType;

			return {
				...state,
			};
		default:
			// persist token only if page reload
			if (state && action.type === '@@INIT') {
				let persist_token = localStorage.getItem('token')
					? localStorage.getItem('token')
					: localStorage.getItem('admin_token');

				return persist_token ? { data: persist_token } : state;
			} else {
				return state;
			}
	}
}
