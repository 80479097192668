import { GET_CART_DATA, GET_UPDATE_CART_DATA } from '../actions/types';

const INTIAL_STATE = { total_iten: 0, cart_items: [], cartProductCodeList: [], distributor: '' };

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INTIAL_STATE, action) {
	switch (action.type) {
		case GET_CART_DATA:
			const data = action.payload?.data ?? {};
			const cartProductCodeList =
				data?.cart_items?.reduce((newArray, obj) => {
					return newArray.concat(obj.product_code);
				}, []) ?? [];
			return {
				...action.payload,
				cartProductCodeList,
				total_iten: data?.cart_items?.length ?? 0,
			};
		case GET_UPDATE_CART_DATA:
			const order_edit_data = action.payload?.data ?? {};
			const orderEditList =
				order_edit_data?.cart_items?.reduce((newArray, obj) => {
					return newArray.concat(obj.product_code);
				}, []) ?? [];
			return {
				...action.payload,
				orderEditList,
				total_iten: order_edit_data?.cart_items?.length ?? 0,
			};

		default:
			// persist token only if page reload
			if (state && action.type === '@@INIT') {
				let persist_token = localStorage.getItem('token')
					? localStorage.getItem('token')
					: localStorage.getItem('admin_token');

				return persist_token ? { data: persist_token } : state;
			} else {
				return state;
			}
	}
}
