export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCES';
export const PRODUCT_TYPE = 'PRODUCT_TYPE';
export const ADMIN_LOGIN_SUCCESS = 'ADMIN_LOGIN_SUCCESS';
export const ADMIN_LOGIN_FAIL = 'ADMIN_LOGIN_FAIL';
export const ADMIN_LOGOUT_SUCCESS = 'ADMIN_LOGOUT_SUCCES';
export const ADMIN_PRODUCT_TYPE = 'ADMIN_PRODUCT_TYPE';
//modal

export const COMMON_MODAL = 'COMMON_MODAL';
//Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const GET_CART_DATA = 'GET_CART_DATA';
export const GET_UPDATE_CART_DATA = 'GET_UPDATE_CART_DATA';

//Upload order
export const ADD_UPLOAD_ORDER = 'ADD_UPLOAD_ORDER';
export const REMOVE_UPLOAD_ORDER = 'REMOVE_UPLOAD_ORDER';

//For loading

export const LOADER_ON = 'LOADER_ON';
export const LOADER_OFF = 'LOADER_OFF';

//file upload
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const REMOVE_FILE = 'REMOVE_FILE';

//distributor data

export const ADD_CART_DATA = 'ADD_CART_DATA';
export const REMOVE_CART_DATA = 'REMOVE_CART_DATA';

export const INVOICE_SET_FILTER = 'INVOICE_SET_FILTER';
